
import { BrowserRouter, Switch, Route} from "react-router-dom";
import Connexion from "./pages/Connexion";
import Projets from "./pages/Projets";
import Tickets from "./pages/Tickets";
import Budgets from "./pages/Budgets";
import VueClient from "./pages/VueClient";
import React, {useState,useEffect} from "react"

const App = () => {
  const [isConnect, setisConnect] = useState(false)

  useEffect(()=>{
    if(localStorage.getItem('is_connect')){
    setisConnect(true)
    }
  },[])
  
  return (
    <BrowserRouter>
      <Switch> 
        <Route path="/token/:token" component={Connexion} />
        <Route path="/" exact component={Connexion} />
        <Route path="/suivi-budgets" when={isConnect}  exact component={Budgets} />
        <Route path="/projets" when={isConnect}  exact component={Projets} />
        <Route path="/projets/:idProjet/:idTicket" when={isConnect}  component={Tickets} />
        <Route path="/projets/:idProjet" when={isConnect}  component={Tickets} />
        <Route path="/client/:uuid" component={VueClient} />
        <Route path='*' when={!isConnect} component={Connexion} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
