import { useParams } from 'react-router-dom';
import Moment from 'moment';

import React, {useState, useEffect} from "react"
import axios from "axios";
import InfoProjet from '../components/InfoProjet';



const VueClient = () => {

    const {uuid} = useParams();
    //const {idTicket} = useParams();

    const [projet, setProjet] = useState()
    const [orderTicketbyDate, setorderTicketbyDate] = useState(false)
    const [connexionClient,setconnexionClient] = useState(true)
    const [password,setPassword] = useState('')
    const [statusConnexion,setstatusConnexion] = useState(true)

    const [modeAffichage, setmodeAffichage] = useState('tout')
    const [periode, setPeriode] = useState(Moment().format())

    const [tickets, setTickets] = useState()

    

    useEffect(() => {
        
        
    },[])

    const testPassword = (e) => {
        e.preventDefault()
        axios.get(process.env.REACT_APP_API_URL+'token/client/'+uuid+'/'+password).then((res) => {
            if(res.data.is_connect){
                axios.defaults.headers.common['Authorization']  = 'Bearer '+res.data.token_client
                axios.get(process.env.REACT_APP_API_URL+'projets/'+uuid+'/').then((res) => {
                    setProjet(res.data)
                    setTickets(res.data.tickets)
                    setconnexionClient(false)
                })
                
                
            }else{
                setstatusConnexion(false)
            }
           
        })


    }

    const orderTicket = () => {
        if(orderTicketbyDate){
            projet.tickets = projet.tickets.sort(function(a,b){return new Date(b.CreatedAt) - new Date(a.CreatedAt)});
            setorderTicketbyDate(false)
        }else{
            projet.tickets.sort(function(a,b){return new Date(a.CreatedAt) - new Date(b.CreatedAt)})
            setorderTicketbyDate(true)
        }

    } 



    useEffect(()=>{
        
        console.log(modeAffichage)
        if(modeAffichage === 'annee'){
           let ticket_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('YY') === Moment(periode).format('YY'))
           setTickets(ticket_find)
        }

        if(modeAffichage === 'trimestre'){
            let ticket_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('Q/YY') === Moment(periode).format('Q/YY'))
            setTickets(ticket_find)
        }

        if(modeAffichage === 'mois'){
            let ticket_find = projet.tickets.filter(ticket => Moment(ticket.CreatedAt).format('MM/YY') === Moment(periode).format('MM/YY'))
            setTickets(ticket_find)
        }

        if(modeAffichage === 'tout' && projet){
            setTickets(projet.tickets)
        }
    },[modeAffichage,projet,periode])



    return (
        <>
        {connexionClient && <>

            <div className="connexion">
                <div className="container">
                    <img src="../../img/logo_ticket.png" alt="logo_ticket" />

                    <form onSubmit={testPassword}>
                        <div>
                            <input type="password" onChange={(e)=>{setPassword(e.target.value)}} placeholder="Mot de passe ..."/>
                        </div>
                        
                        <button type="submit">Se connecter</button>
                        {!statusConnexion && <p className="error">Accès a l'application refusé !</p>}
                    </form>
                </div>
            </div>
        
        </>}
        {projet &&
        <>
      
            <div className="tickets">
                <div className="main">
                <InfoProjet projet={projet} VueClient={true} modeAffichage={modeAffichage} setModeAffichage={setmodeAffichage} periode={periode} setPeriode={setPeriode}/>
        

                    <div className="tickets-container client">

                    <div className="header-table">
                            <div className="date" onClick={orderTicket}>
                                <p className="createdAt">Date 
                                    {!orderTicketbyDate && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" className="svg-inline--fa fa-sort-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>}
                                    {orderTicketbyDate && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" className="svg-inline--fa fa-sort-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>}
                                </p>
                            </div>

                            <div className="name-ticket">
                                    <p className="nom-ticket">Nom du ticket</p>
                            </div>
                                <div className="description">
                                    <p>Description</p>
                                </div>

                                <div className="data">
                                    <p className="cout cout-demandeur">Demandeur</p>
                                </div>
                                <div className="data">
                                    <p className="cout cout-ticket">Nbr. Tickets</p>
                                </div>
                                <div className="data">
                                    <p className="cout cout-temps">Temps passés</p>
                                </div>
                                <div className="data">
                                    <p className="cout cout-euro">Coût en €</p>
                                </div>
                    </div>
                            <div class="tickets-body">
                                {tickets && tickets.map((ticket,key)=>(
                                    <>

                                {key !== 0 && ticket.PriceTicket !== tickets[key-1].PriceTicket && <>
                                    <div class="new_cost">
                                        Le prix du ticket est passé de {ticket.PriceTicket}€ à {tickets[key-1].PriceTicket}€ !  
                                    </div> 
                                    </>}
                                    
                                <div className="ticket client" key={ticket.idTicket}>
                                        <div className="date">
                                    <p className="createdAt">{Moment(ticket.CreatedAt).format('L')}</p>
                                </div>
                                <div className="name-ticket">
                                    <p className="nom-ticket">{ticket.NameTicket}</p>
                                </div>
                                <div className="description">
                                    <p>{ticket.Description}</p>
                                </div>

                                <div className="data">
                                    <p className="cout  cout-demandeur">{ticket.Demandeur}</p>
                                </div>

                                <div className="data">
                                    <p className="cout cout-ticket">{ticket.coutTicket}</p>
                                </div>
                                <div className="data">
                                    <p className="cout cout-temps">{ticket.coutTicket/4}</p>
                                </div>
                                <div className="data">
                                    <p className="cout cout-euro">{ticket.coutTicket*ticket.PriceTicket}€</p>
                                </div>
                            </div>
                            </> 
                        ))}
                    </div>
                </div>
            </div>
        </div>
        </>
        }
        </>
    )

}

export default VueClient;