import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from 'react-router-dom';
import { ReactSortable } from "react-sortablejs";
import NumberFormat from 'react-number-format';
import Select from "react-select";
import Moment from "moment";
import ModalSuiviBudget from "./modals/ModalSuiviBudget";

const AllEnveloppes = () => {
    const [projetsEnveloppes, setProjetsEnveloppes] = useState([])
    const [projetsClassiques, setProjetsClassiques] = useState([])
    const [projetsBudgetsPlancher, setProjetsBudgetsPlancher] = useState([])

    const [modeAffichage, setModeAffichage] = useState('encours')
    const [periodeString, setperiodeString] = useState()
    const [periode, setPeriode] = useState(Moment().format())
    const [debutTrimestreApiCall, setDebutTrismestreApiCall] = useState(Moment().startOf('quarter').format("YYYY-MM-DD"))
    const [finTrimestreApiCall, setFinTrismestreApiCall] = useState(Moment().endOf('quarter').format("YYYY-MM-DD"))
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [modalSuiviBudget, setmodalSuiviBudget] = useState(false)
    const [projetInModal, setprojetInModal] = useState()


    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

    useEffect(() => {

        if (modeAffichage === 'encours') {
            // Periode corespondant au jour actuel
            setIsLoadingData(true);
            setPeriode(Moment().format())

            setDebutTrismestreApiCall(Moment(periode, "YYYY-MM-DD").startOf('quarter').format("YYYY-MM-DD"))
            setFinTrismestreApiCall(Moment(periode, "YYYY-MM-DD").endOf('quarter').format("YYYY-MM-DD"))

            // On vide les projets
            setProjetsEnveloppes([])
            setProjetsClassiques([])
            setProjetsBudgetsPlancher([])

            let isMounted = true;

            axios.get(process.env.REACT_APP_API_URL + 'enveloppes')
                .then((res) => {
                    if (res.data.length > 0 && isMounted ) {
                        setIsLoadingData(false);
                        setProjetsEnveloppes(res.data.filter(item => item.modeProjet === "enveloppe"))
                        setProjetsClassiques(res.data.filter(item => item.modeProjet === "classique"))
                        setProjetsBudgetsPlancher(res.data.filter(item => item.modeProjet === "budget-plancher"))
                    }
                    return () => {
                        isMounted = false;
                    };
                })
                .catch(function (error) {
                    if (error.response) {
                        // Status Code différent de 200
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // La requête a été effectué mais il n'y a pas de reponse. 
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });

        } else {
            // Mode par Trimestre. 
            // On vide les projets
            setProjetsEnveloppes([])
            setProjetsClassiques([])
            setProjetsBudgetsPlancher([])
            setIsLoadingData(true);

            let isMounted = true;
            axios.get(process.env.REACT_APP_API_URL + 'enveloppesByTrimestre/' + debutTrimestreApiCall + '/' + finTrimestreApiCall + '/')
                .then((res) => {
                    if (res.data.length > 0 && isMounted) {
                        setIsLoadingData(false);
                        setProjetsEnveloppes(res.data.filter(item => item.modeProjet === "enveloppe"))
                        setProjetsClassiques(res.data.filter(item => item.modeProjet === "classique"))
                        setProjetsBudgetsPlancher(res.data.filter(item => item.modeProjet === "budget-plancher"))
                    }
                    return () => {
                        isMounted = false;
                        };
                })
                .catch(function (error) {
                    if (error.response) {
                        // Status Code différent de 200
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // La requête a été effectué mais il n'y a pas de reponse. 
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        }
    }, [periode, modeAffichage, debutTrimestreApiCall, finTrimestreApiCall])

    const handleClickEdity = (projet) => {
        setprojetInModal(projet)
        setmodalSuiviBudget(true)
    }

    const changePeriode = (sens) => {

        // console.log(sens)
        const seconde_in_year = 31536000

        if (modeAffichage === 'trimestre') {
            const time = sens === 'prev' ? -(seconde_in_year / 4) : seconde_in_year / 4
            const date = Moment.unix(parseFloat(Moment(periode).unix()) + time).format()

            setPeriode(date)
            // console.log("periode on change : " + periode)
            setDebutTrismestreApiCall(Moment(date, "YYYY-MM-DD").startOf('quarter').format("YYYY-MM-DD"))
            setFinTrismestreApiCall(Moment(date, "YYYY-MM-DD").endOf('quarter').format("YYYY-MM-DD"))
        }

    }

    useEffect(() => {
        if (modeAffichage === 'trimestre') {
            setperiodeString(`${Moment(periode).format('Q')}${Moment(periode).format('Q') === '1' ? 'er' : 'eme'} trimestre ${Moment(periode).format('Y')}`)
        }
    }, [modeAffichage, periode])

    const optionsAffichage = [
        { value: 'encours', label: 'Trimestre en cours' },
        { value: 'trimestre', label: 'Trimestre' },
    ]

    return (
        <div className="main">
            <div className="budgets">

                <div className="container-top">
                    <div className="titre-section">
                        <h1>Tous les budgets</h1>
                    </div>
                    <div className="periode">
                        <div className="select-periode">
                            {modeAffichage !== 'encours' &&
                                <>
                                    <div>
                                        <svg aria-hidden="true" onClick={() => { changePeriode('prev') }} focusable="false" data-prefix="far" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>
                                        <svg aria-hidden="true" onClick={() => { changePeriode('next') }} focusable="false" data-prefix="far" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
                                    </div>
                                    <p>{periodeString}</p>
                                </>
                            }
                            <Select className="selection-periode"
                                blurInputOnSelect={false}
                                options={optionsAffichage}
                                defaultValue={optionsAffichage.find(element => element.value === modeAffichage)}
                                onChange={(event) => { setModeAffichage(event.value) }}
                                isSearchable={false} />
                        </div>
                    </div>
                </div>

                <h2 className="titre-tableau">Projets avec Enveloppes</h2>
                <div className="budgets-container">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Projet</th>
                                <th scope="col">Consommation Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Enveloppes Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Total consommation</th>
                                <th scope="col">Total enveloppes</th>
                                <th scope="col">Enveloppe restante</th>
                                <th scope="col">Dernière Facture</th>
                                <th scope="col">Commentaire</th>
                                <th className="editer" scope="col">Editer</th>
                            </tr>
                        </thead>

                        {isLoadingData ?
                            <tbody>
                                <div className="spinner-container">
                                    <div className="spinner-download"></div>
                                </div>
                            </tbody>

                            :
                            <ReactSortable tag="tbody" id="section-all-enveloppes" group="projets-shared" list={projetsEnveloppes} animation={200} disabled={true} setList={setProjetsEnveloppes} >
                                {projetsEnveloppes.map((projetBudget) => (

                                    <tr id={`projet-${projetBudget.idProjet}`} key={projetBudget.idProjet}>
                                        <td data-label="Projet">
                                            <Link to={`/projets/${projetBudget.idProjet}`} className="link-projet">
                                                <img src={process.env.REACT_APP_API_URL + 'uploads/' + projetBudget.UrlLogo} alt="logo" />
                                                <p>{projetBudget.NameProjet}</p>
                                            </Link>
                                        </td>
                                        <td data-label={`Consommation Trimestre ${Moment(periode).format("Q/YYYY")}`} ><NumberFormat value={projetBudget.ConsoCurrentTrimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label={`Enveloppes Trimestre ${Moment(periode).format("Q/YYYY")}`} ><NumberFormat value={projetBudget.Enveloppe_Trimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label="Total consommation"><NumberFormat value={projetBudget.ConsoTotale} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label="Total enveloppes"><NumberFormat value={projetBudget.EnveloppeSomme} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label="Enveloppe restante"><p className={projetBudget.Enveloppe_Restante > 0 ? 'enveloppe' : 'enveloppe warning'}><NumberFormat value={projetBudget.Enveloppe_Restante} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></p></td>
                                        <td data-label="Dernière Facture">{!projetBudget.DateLastInvoice ? '-' : Moment(projetBudget.DateLastInvoice).format('DD/MM/YYYY')}</td>
                                        <td data-label="Commentaire">{!projetBudget.Comments ? '-' : projetBudget.Comments}</td>
                                        <td data-label="Editer">
                                            <div className="button" onClick={() => { handleClickEdity(projetBudget) }} >
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" className="svg-inline--fa fa-cog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
                                            </div>
                                            {modalSuiviBudget && <ModalSuiviBudget key={projetBudget.idProjet} showModal={modalSuiviBudget} setShowModal={setmodalSuiviBudget} projetBudget={projetBudget} projetInModal={projetInModal} />}
                                        </td>
                                    </tr>

                                ))}
                            </ReactSortable>
                        }
                    </table>
                </div>


                <h2 className="titre-tableau">Projets avec Budget Plancher</h2>
                <div className="budgets-container">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Projet</th>
                                <th scope="col">Enveloppe générée chaque mois</th>
                                <th scope="col">Consommation Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Enveloppes Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Enveloppe restante Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Dernière Facture</th>
                                <th scope="col">Commentaire</th>
                                <th className="editer" scope="col">Editer</th>
                            </tr>
                        </thead>
                        {isLoadingData ?
                            <tbody>
                                <div className="spinner-container">
                                    <div className="spinner-download"></div>
                                </div>
                            </tbody>
                            :
                            <ReactSortable tag="tbody" id="section-all-enveloppes" group="projets-shared" list={projetsBudgetsPlancher} animation={200} disabled={true} setList={setProjetsBudgetsPlancher} >
                                {projetsBudgetsPlancher.map((projetBudget) => (
                                    <tr id={`projet-${projetBudget.idProjet}`} key={projetBudget.idProjet}>
                                        <td data-label="Projet">
                                            <Link to={`/projets/${projetBudget.idProjet}`} className="link-projet">
                                                <img src={process.env.REACT_APP_API_URL + 'uploads/' + projetBudget.UrlLogo} alt="logo" />
                                                <p>{projetBudget.NameProjet}</p>
                                            </Link>
                                        </td>
                                        <td data-label="Enveloppe générée chaque mois" ><NumberFormat value={projetBudget.enveloppeToGenerate !== null ? projetBudget.enveloppeToGenerate : 0} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label={`Consommation Trimestre ${Moment(periode).format("Q/YYYY")}`} ><NumberFormat value={projetBudget.ConsoCurrentTrimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label={`Enveloppes Trimestre ${Moment(periode).format("Q/YYYY")}`} ><NumberFormat value={projetBudget.Enveloppe_Trimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label={`Enveloppe restante ${Moment(periode).format("Q/YYYY")}`}><p className={projetBudget.Enveloppe_Restante_Trimestre >= 0 ? 'enveloppe' : 'enveloppe warning'}><NumberFormat value={projetBudget.Enveloppe_Restante_Trimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></p></td>
                                        <td data-label="Dernière Facture">{!projetBudget.DateLastInvoice ? '-' : Moment(projetBudget.DateLastInvoice).format('DD/MM/YYYY')}</td>
                                        <td data-label="Commentaire">{!projetBudget.Comments ? '-' : projetBudget.Comments}</td>
                                        <td data-label="Editer">
                                            <div className="button" onClick={() => { handleClickEdity(projetBudget) }} >
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" className="svg-inline--fa fa-cog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
                                            </div>
                                            {modalSuiviBudget && <ModalSuiviBudget key={projetBudget.idProjet} showModal={modalSuiviBudget} setShowModal={setmodalSuiviBudget} projetBudget={projetBudget} projetInModal={projetInModal} />}
                                        </td>
                                    </tr>
                                ))}
                            </ReactSortable>
                        }
                    </table>
                </div>

                <h2 className="titre-tableau">Projets avec Suivi au réel</h2>
                <div className="budgets-container">

                    <table>

                        <thead>
                            <tr>
                                <th scope="col">Projet</th>
                                <th scope="col">Consommation Trimestre {Moment(periode).format("Q/YYYY")}</th>
                                <th scope="col">Dernière Facture</th>
                                <th scope="col">Commentaire</th>
                                <th className="editer" scope="col">Editer</th>
                            </tr>
                        </thead>
                        {isLoadingData ?
                            <tbody>
                                <div className="spinner-container">
                                    <div className="spinner-download"></div>
                                </div>
                            </tbody>
                            :
                            <ReactSortable tag="tbody" id="section-all-enveloppes" group="projets-shared" list={projetsClassiques} animation={200} disabled={true} setList={setProjetsClassiques} >
                                {projetsClassiques.map((projetBudget) => (

                                    <tr id={`projet-${projetBudget.idProjet}`} key={projetBudget.idProjet}>
                                        <td data-label="Projet">
                                            <Link to={`/projets/${projetBudget.idProjet}`} className="link-projet">
                                                <img src={process.env.REACT_APP_API_URL + 'uploads/' + projetBudget.UrlLogo} alt="logo" />
                                                <p>{projetBudget.NameProjet}</p>
                                            </Link>
                                        </td>
                                        <td data-label={`Consommation Trimestre ${Moment(periode).format("Q/YYYY")}`} ><NumberFormat value={projetBudget.ConsoCurrentTrimestre} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€" /></td>
                                        <td data-label="Dernière Facture">{!projetBudget.DateLastInvoice ? '-' : Moment(projetBudget.DateLastInvoice).format('DD/MM/YYYY')}</td>
                                        <td data-label="Commentaire">{!projetBudget.Comments ? '-' : projetBudget.Comments}</td>
                                        <td data-label="Editer">
                                            <div className="button" onClick={() => { handleClickEdity(projetBudget) }} >
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" className="svg-inline--fa fa-cog fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>
                                            </div>
                                            {modalSuiviBudget && <ModalSuiviBudget key={projetBudget.idProjet} showModal={modalSuiviBudget} setShowModal={setmodalSuiviBudget} projetBudget={projetBudget} projetInModal={projetInModal} />}
                                        </td>
                                    </tr>
                                ))}
                            </ReactSortable>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}
export default AllEnveloppes;