import { useParams } from 'react-router-dom';
import Header from "../components/Header";
import InfoProjet from "../components/InfoProjet";
import AllTickets from "../components/AllTickets";

import React, {useState, useEffect} from "react"
import axios from "axios";
import Moment from 'moment';



const Tickets = () => {

    const {idProjet} = useParams();
    //const {idTicket} = useParams();

    const [projet, setProjet] = useState()
    const [needReload, setneedReload] = useState(false)


    const [modeAffichage, setmodeAffichage] = useState('tout')
    const [periode, setPeriode] = useState(Moment().format())

    const [ticketsSearched,setTicketsSearched] = useState([])

    useEffect(() => {
        localStorage.setItem('modeAffichage',modeAffichage)
    }, [modeAffichage,periode])

  
    axios.defaults.headers.common['Authorization']  = 'Bearer '+localStorage.getItem('token')
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'projets/'+idProjet).then((res) => {
            setProjet(res.data)
            setneedReload(false)
        })
    },[needReload,idProjet])

    

    const request_need_reload = (request) => {

        const adresse_no_reload = [process.env.REACT_APP_API_URL+'projets/',process.env.REACT_APP_API_URL+'search/'] 
        const adresse_do_reload = [process.env.REACT_APP_API_URL+'projets/logo'] 
        const find = adresse_no_reload.find(adresse => request.includes(adresse)) && !adresse_do_reload.find(adresse => request.includes(adresse))
        return find ? false : true;

    }

    axios.interceptors.response.use(function (response) {
        console.log('Réponse AJAX - ', response.config.url)
        if(request_need_reload(response.config.url)){
            setneedReload(true)
        }
        return response
    })


    return (
        
        <div className="tickets">
            <Header  page="projet" idProjet={idProjet}/>
            <div className="main">
                {projet && <InfoProjet ticketsSearched={ticketsSearched} projet={projet} VueClient={false} modeAffichage={modeAffichage} setModeAffichage={setmodeAffichage} periode={periode} setPeriode={setPeriode}/> }
                {projet && projet.tickets &&  <AllTickets
                setTicketsSearched={setTicketsSearched}
                ticketsContainer={projet.tickets} modeAffichage={modeAffichage} periode={periode} />}
                
            </div>
        </div>
    )

}

export default Tickets;