import AllProjets from "../components/AllProjets";
import Header from "../components/Header";
import React, {useState, useEffect} from "react"


const Projets = () => {
    return (
        <div className="tickets">
            <Header page="projets"/>
            <AllProjets />
        </div>
    )
}
export default Projets;