import axios from "axios";
import React, {useState} from "react"
import { Link } from "react-router-dom";



const ModalInfosClient = ({showModal,setShowModal,projet}) =>{

    const showHideClasseName = showModal ? "modal open" : "modal close";


    const [password,setPassword] = useState(projet.passwordClient)
    const [success,setsucess] = useState(false)



   


  
    const handleSumbit = (e) => {
        e.preventDefault()

     
        let qs = 'passwordClient='+password+'&idProjet='+projet.idProjet
 
        axios({
                method: "put",
                url: process.env.REACT_APP_API_URL+"projets",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs
        }).then(()=>{
            setsucess(true)    
        })
  
    }
        
        return(
            
            <div className={showHideClasseName}>
                <div className="modal-container">
                
                <div className="modal-header">
                    <h1>Partager la vue client</h1>
                    <button type="button" className="close" onClick={()=>{setShowModal(false)}}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                    </svg>
                    </button>
                </div>
                <div className="modal-content">
                    

                    <p>Lien vers la page client :</p>
                    <p><Link to={`/client/${projet.uuid}`}>https://ticket.r2.fr/client/{projet.uuid}</Link></p>

        
                    <form onSubmit={(e) => handleSumbit(e)}>
                    <div>
                        <input onChange={(e) => setPassword(e.target.value)} value={password} type="text" placeholder="Mot de passe" />
                        {success && <p className="success">Mot de passe modifié avec succès</p>}
                    </div>
                    
                    <div>
                        <button type="submit">Changer le mot de passe</button>
                    </div>
                </form>

                   

                    

                </div>
                </div>
            </div>
        )


}
export default ModalInfosClient