import { NavLink,useHistory, Link} from "react-router-dom";
import React, {useState, useEffect} from "react"
// import md5 from "js-md5"
import axios from "axios";
import Moment from "moment";
import logoR2 from "../assets/img/R2_LOGOSEUL.png";


const Header = (props) => {

    const history = useHistory();
    const { header,idProjet } = props
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profileGoogle')))
    const [search, setsearch] = useState('')
    const [searchResult,setsearchResult] = useState('')


    const quitApp = () =>{
        localStorage.removeItem('is_connect')
        localStorage.removeItem('token')
        localStorage.removeItem('token_createdAt')
        localStorage.removeItem('profileGoogle')
        history.push("/")
    }

    useEffect(()=>{

        let formData = new FormData()
        formData.append('search',search)
        formData.append('type_recherche',props.page === 'projet' ? 'ticket' : 'projet')
        formData.append('idProjet',idProjet ? idProjet : 0)

        if(search.length > 2){
            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL+"search",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
                console.log(res.data)
                setsearchResult(res.data)
            })
        }
    },[search])


    const redirection_nav = (link) => {
        history.push(link)
        setsearch('')
        setsearchResult([])

    }
    return (
        <div className="header">
            <div className="header-container">
            <div className="logo-container">
                {props.page === 'projet' && 
                <NavLink exact to='/projets'>
                    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.82857 1.38L2 7.5M2 7.5L7.82857 13.62M2 7.5H19" stroke="black" stroke-width="2.176" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </NavLink> 
                }

                {props.page === 'budgets' && 
                <NavLink exact to='/projets'>
                    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.82857 1.38L2 7.5M2 7.5L7.82857 13.62M2 7.5H19" stroke="black" stroke-width="2.176" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </NavLink>
                }

                <div className="logo-r2">
                    <img src={logoR2} alt="logo r2" />
                </div>
            </div>

            <div className={search.length >0 && searchResult.length > 0 ? 'results-open search-container' : 'search-container'}>
                <div className="search">
                   <div className="loupe">
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 19L14.9862 14.9791M17.2105 9.60526C17.2105 11.6223 16.4093 13.5567 14.983 14.983C13.5567 16.4093 11.6223 17.2105 9.60526 17.2105C7.58822 17.2105 5.65379 16.4093 4.22753 14.983C2.80127 13.5567 2 11.6223 2 9.60526C2 7.58822 2.80127 5.65379 4.22753 4.22753C5.65379 2.80127 7.58822 2 9.60526 2C11.6223 2 13.5567 2.80127 14.983 4.22753C16.4093 5.65379 17.2105 7.58822 17.2105 9.60526Z" stroke="black" stroke-width="2.125" stroke-linecap="round"/>
                        </svg>
                   </div>
                   <input type="text" name="recherche" autocomplete="off" placeholder={`Rechercher ${props.page === 'projet' ? 'une demande ...' : 'un projet ...'}`} value={search} onChange={(e) => setsearch(e.target.value)}></input>
                   {search.length>0 &&
                   
                   <div class="croix" onClick={()=>{setsearch('')}}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
                   </div>
                   }
                </div>
                
                    <div className="search-results">
                    
                    {searchResult && searchResult.map(result=>(
                        <>
                        
                        {result.type === "projet" &&
                        
                        
                        <div class="projet" onClick={()=>{redirection_nav(`/projets/${result.idProjet}/`)}}>
                            <div class="logo">
                                <img src={process.env.REACT_APP_API_URL+'uploads/'+result.UrlLogo} alt="logo" />
                            </div>
                            <div>
                                <h2>{result.NameProjet}</h2>
                                <p>Projet créé le {Moment(result.CreatedAt).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                       
                        
                    
                        }

                    {result.type === "ticket" &&
                        
                        <div class="ticket" onClick={()=>{redirection_nav(`/projets/${result.fk_idProjet}/${result.idTicket}`)}}>
                            <div>
                                <h2>{result.NameTicket}</h2>
                                <p>{result.Description}</p>
                            </div>
                        </div>
                        
                    
                        }

                        
                        </>
                    ))}
                      



                    </div>
            </div>

         

            <div class="login">

                <img src={user.imageUrl} alt="thumbnail" />
                <div>
                    <p>{user.familyName}</p>
                    <p class="given-name">{user.givenName}</p>
                </div>

                <div className="dropdown-menu">
                    <a href="#" onClick={quitApp}>Déconnexion</a>
                </div>

            </div>

            </div>
        </div>
    )


}
export default Header;