
import Moment from "moment";
import React, {useState, useEffect} from "react"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import { BarChart, Bar, Cell, Legend, ResponsiveContainer } from 'recharts';



const Chart = (props) => {

    const { projet } = props
    const { ticketsFind } = props

    const [tickets,settickets] = useState();
    const [percentPoste,setpercentPoste] = useState();

    useEffect(() => {
       triProjet_ticketperdate(projet)
       triProjet_percentPost(ticketsFind)
    },[props])

    const triProjet_ticketperdate= (projet) => {
        let projetTrie = []
        if(props.modeAffichage === 'tout' || props.modeAffichage === 'mois'){
            projet.tickets.forEach(ticket => {
                let month = Moment(ticket.CreatedAt).format('MM/YYYY')
                if(!projetTrie.find(item=> item.month === month)){
                    projetTrie.push({month:month,tickets:ticket.coutTicket})
                }else{
                    let index = projetTrie.findIndex(item => item.month === month)
                    projetTrie[index].tickets = projetTrie[index].tickets+ticket.coutTicket
                }
            });
        }

        if(props.modeAffichage === 'annee'){
            projet.tickets.forEach(ticket => {
                let year = Moment(ticket.CreatedAt).format('YYYY')
                if(!projetTrie.find(item=> item.year === year)){
                    projetTrie.push({year:year,tickets:ticket.coutTicket})
                }else{
                    let index = projetTrie.findIndex(item => item.year === year)
                    projetTrie[index].tickets = projetTrie[index].tickets+ticket.coutTicket
                }
            });
        }

        if(props.modeAffichage === 'trimestre'){
            projet.tickets.forEach(ticket => {
                let trimestre
                const date =  Moment(ticket.CreatedAt).format('MM')
    
                if(date === '01' || date === '02' || date === '03'){
                    trimestre = 'T1-'+Moment(ticket.CreatedAt).format('YYYY')
                }else if(date === '04' || date === '05' || date === '06'){
                    trimestre = 'T2-'+Moment(ticket.CreatedAt).format('YYYY')
                }else if(date === '07' || date === '08' || date === '09'){
                    trimestre = 'T3-'+Moment(ticket.CreatedAt).format('YYYY')
                }else if(date === '10' || date === '11' || date === '12'){
                    trimestre = 'T4-'+Moment(ticket.CreatedAt).format('YYYY')
                }

                if(!projetTrie.find(item=> item.trimestre === trimestre)){
                    projetTrie.push({trimestre:trimestre,tickets:ticket.coutTicket})
                }else{
                    let index = projetTrie.findIndex(item => item.trimestre === trimestre)
                    projetTrie[index].tickets = projetTrie[index].tickets+ticket.coutTicket
                }
            });

        }


        projetTrie.reverse()
        settickets(projetTrie)

    }

    const triProjet_percentPost = (tickets) => {

        let projetTrie = []
        tickets.forEach(ticket => {
            ticket.tasks.forEach(task =>{

                if(!projetTrie.find(item=> item.Category === task.Category)){
                    projetTrie.push({Category:task.Category,Tickets:parseFloat(task.costTask)})
                }else{
                    let index = projetTrie.findIndex(item => item.Category === task.Category)
                    projetTrie[index].Tickets = projetTrie[index].Tickets+parseFloat(task.costTask)
                }
            })
        });
        setpercentPoste(projetTrie)

    }



    return (
        <>
            {!props.VueClient && 
            <div className="chart-container charts-postes">
                <h2>Nombre de tickets par poste</h2>
                <div className="chart">
                    
                    <BarChart
                    width={400}
                    height={250}
                    data={percentPoste}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 5,
                    }}
                    stackOffset='expand'
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        {/* <XAxis dataKey="Category" interval={0} dy={4} angle={-11} domain={['auto', 'auto']}/> */}
                        <XAxis dataKey="Category" hide={true}/>
                        <YAxis data/>
                        <Tooltip />
                        <Bar dataKey="Tickets" label="Nombre de ticket" fill="#3164A099" />
                    </BarChart>
            

                </div>
            </div>
            }
            <div className="chart-container chart-month">

            {props.modeAffichage === "tout" &&
                <h2>Nombre de tickets</h2>
            }
            {props.modeAffichage === "annee" &&
                <h2>Nombre de tickets par année</h2>
            }
            {props.modeAffichage === "trimestre" &&
                <h2>Nombre de tickets par trimestre</h2>
            }
            {props.modeAffichage === "mois" &&
                <h2>Nombre de tickets par mois</h2>
            }
                
            <div className="chart">
            <AreaChart
                width={400}
                height={250}
                data={tickets}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {props.modeAffichage === "tout" &&
                    <XAxis dataKey="month" hide={true} />
                }
                {props.modeAffichage === "annee" &&
                    <XAxis dataKey="year" hide={true}/>
                }
                {props.modeAffichage === "trimestre" &&
                    <XAxis dataKey="trimestre" hide={true}/>
                }

                {props.modeAffichage === "mois" &&
                    <XAxis dataKey="month" hide={true} />
                }
                <YAxis/>
                <Tooltip />
                    <Area type="monotone" name="Tickets" dataKey="tickets" stroke="#3164A0" fill="#3164A0" />
            </AreaChart>
            </div>   
        </div>
      </>
    )


}
export default Chart;
