import { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import Ticket from "./Ticket.js";
import ModalTicket from "./modals/ModalTicket.js";
import { useHistory } from "react-router";
import Moment from "moment";
import NumberFormat from "react-number-format";
import axios from "axios";





const AllTickets = (props,idProjet) => {

    let {ticketsContainer} = props
    let {modeAffichage} = props
    let {periode} = props
    // let {setTicketsSearched} = props

    const [tickets,setTickets] =  useState(ticketsContainer)
    const [showModalTicket,setshowModalTicket] = useState()
    const [orderTicketbyDate, setorderTicketbyDate] = useState(false)
    const [modalTicket, setmodalTicket] = useState(false)
    const [ticketInModal,setticketInModal] = useState()
    const history = useHistory();
    
    const [search, setsearch] = useState('')
    const [ticketsSearched,setTicketsSearched] = useState([])


    // Recherche Tickets par nom
    useEffect(()=>{
       let result = ticketsContainer.filter(item => item.NameTicket.includes(search))
    //    setsearch(result)
       setTickets(result)
    },[search])
     
    const orderTicket = () => {
        if(orderTicketbyDate){
            tickets.sort(function(a,b){return new Date(b.CreatedAt) - new Date(a.CreatedAt)});
            setorderTicketbyDate(false)
        }else{
            tickets.sort(function(a,b){return new Date(a.CreatedAt) - new Date(b.CreatedAt)})
            setorderTicketbyDate(true)
        }
    } 
    
    const {idTicket} = useParams()
    useEffect(()=>{
        const ticket = tickets.find(ticket => ticket.idTicket === idTicket)

        if(ticket){
            setticketInModal(ticket)
            setmodalTicket(true)
        }
    },[idTicket,tickets])


    useEffect(()=>{
        
        console.log(modeAffichage)
        if(modeAffichage === 'annee'){
           let ticket_find = ticketsContainer.filter(ticket => Moment(ticket.CreatedAt).format('YY') === Moment(periode).format('YY'))
           setTickets(ticket_find)
        }

        if(modeAffichage === 'trimestre'){
            let ticket_find = ticketsContainer.filter(ticket => Moment(ticket.CreatedAt).format('Q/YY') === Moment(periode).format('Q/YY'))
            setTickets(ticket_find)
        }

        if(modeAffichage === 'mois'){
            let ticket_find = ticketsContainer.filter(ticket => Moment(ticket.CreatedAt).format('MM/YY') === Moment(periode).format('MM/YY'))
            setTickets(ticket_find)
        }

        if(modeAffichage === 'tout'){
            setTickets(ticketsContainer)
        }
    },[modeAffichage,ticketsContainer,periode])



    const handleRowClick = (ticket) => {
        setticketInModal(ticket)
        setmodalTicket(true)
        history.push(`/projets/${ticket.idProjet}/${ticket.idTicket}`)
    }

    console.log(tickets)



    return (
        <>

        <div className="search">
            <div className="loupe">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 19L14.9862 14.9791M17.2105 9.60526C17.2105 11.6223 16.4093 13.5567 14.983 14.983C13.5567 16.4093 11.6223 17.2105 9.60526 17.2105C7.58822 17.2105 5.65379 16.4093 4.22753 14.983C2.80127 13.5567 2 11.6223 2 9.60526C2 7.58822 2.80127 5.65379 4.22753 4.22753C5.65379 2.80127 7.58822 2 9.60526 2C11.6223 2 13.5567 2.80127 14.983 4.22753C16.4093 5.65379 17.2105 7.58822 17.2105 9.60526Z" stroke="black" stroke-width="2.125" stroke-linecap="round"/>
                </svg>
            </div>
            <input type="text" name="recherche" autocomplete="off" placeholder={`Filtrer les tickets par nom`} value={search} onChange={(e) => setsearch(e.target.value)}></input>
            {search.length>0 &&
            
            <div class="croix" onClick={()=>{setsearch('')}}>
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg>
            </div>
            }
        </div>
        
        <div className="tickets-container">


        <div className="header-table">
                <div className="date" onClick={orderTicket}>
                    <p className="createdAt">Date 
                        {!orderTicketbyDate && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" className="svg-inline--fa fa-sort-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>}
                        {orderTicketbyDate && <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" className="svg-inline--fa fa-sort-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>}
                    </p>
                </div>

                <div className="name-ticket">
                        <p className="nom-ticket">Nom du ticket</p>
                </div>
                    <div className="description">
                        <p>Description</p>
                    </div>

                    <div className="data">
                        <p className="cout cout-demandeur">Demandeur</p>
                    </div>
                    <div className="data">
                        <p className="cout cout-ticket">Nbr. Tickets</p>
                    </div>
                    <div className="data">
                        <p className="cout cout-temps">Temps passés</p>
                    </div>
                    <div className="data">
                        <p className="cout cout-euro">Coût en €</p>
                    </div>
        </div>
        <div class="tickets-body">

            {tickets.map((ticket,key)=>(<>
                
                {key !== 0 && ticket.PriceTicket !== tickets[key-1].PriceTicket && <>
                <div class="new_cost">
                    Le prix du ticket est passé de {ticket.PriceTicket}€ à {tickets[key-1].PriceTicket}€ !  
                </div> 
                </>}
                <div className="ticket" key={ticket.idTicket} onClick={()=> handleRowClick(ticket)}>
                  <Ticket key={ticket} ticket={ticket}/>
                 </div>
                 </>
                 
             ))}


        </div>


        </div>
        {modalTicket && <ModalTicket showModal={modalTicket} setShowModal={setmodalTicket} ticketInModal={ticketInModal}/>}
        
       
        </>
    )


}
export default AllTickets;