import axios from "axios";
import React, {useState} from "react"
import Papa from "papaparse";



const ModalImportTicket = ({showModal,setShowModal,projet}) =>
{
    const showHideClasseName = showModal ? "modal open" : "modal close";

    const [fileImportTicket,setfileImportTicket] = useState()
    const [tabImportTicket,settabImportTicket] = useState({costTotal:0,postes:[],data:[]})
    const [preview,setpreview] = useState()
    const [confirm,setconfirm] = useState(false)
    axios.defaults.headers.common['Authorization']  = 'Bearer '+localStorage.getItem('token')



    
    const addingFile = (file) => {
             // Check if the file is an image.

         

            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                let result = event.target.result;
                console.log('Document chargé !')
                let data = Papa.parse(result,{header:true}).data

                let costTotal = 0
                let postes = []
                data.forEach((item,keyData)=>{

                    data[keyData].costTicket = 0
                    for (const [key, value] of Object.entries(item)) {
                        if(key !== 'date' && key !== 'demande' && key !== 'sujet' && key !== 'costTicket'){

                            let val = value ? parseFloat(value.toString().replace(',','.')) : 0

                            if(!postes.includes(key)){
                                postes.push(key)
                            }

                            data[keyData][key] = val
                            data[keyData].costTicket += parseFloat(val)
                            costTotal += parseFloat(val)
                        }
                    }
                    
                })
                console.log(costTotal)
                console.log(postes)
                console.log(data)

                settabImportTicket({costTotal:costTotal,postes:postes,data:data})
                setpreview(true)
                console.log(tabImportTicket)

 

            });
            reader.readAsText(file)
           
            
    }

    const handleSubmit = (e) => {
  
        e.preventDefault()

        let formData = new FormData()
        formData.append('file',JSON.stringify(tabImportTicket.data))
        formData.append('idProjet',projet.idProjet)
        formData.append('import',confirm)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"projets/csv/",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {

            if(res.data.import_success){
                setShowModal(false);
                setconfirm(false)
                setfileImportTicket('')
                setpreview(false)
                axios('/')
            }
              
            setpreview(res.data)
            setconfirm(true)

    

        })
    }

  


    
    return(
        <div className={showHideClasseName}>
            <div className="modal-container">
          
            <div className="modal-header">
                <h1>Importer des tickets</h1>
                <button type="button" className="close" onClick={()=>{setShowModal(false)}}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                </svg>
                </button>
                

            </div>
            <div className="modal-content">
                
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div>
                        <p>Importer un fichier CSV, veuillez utiliser ce <a href="https://docs.google.com/spreadsheets/d/1-DbQglUrgo8f7IMr1JZpnP7dn5Wjv-BIN7oJ0uwkU3A/edit?usp=sharing" rel="noreferrer" target="_blank">template</a>.</p>
                        <input type="file" accept=".csv" name="importTicket" onChange={(e)=>{ addingFile(e.target.files[0])}} />
                        
                    </div>
                    <div>
                    {!confirm && <button type="submit">Importer le fichier CSV</button>}
                    {confirm && <button type="submit">Confirmer l'import</button>}
                    </div>
                </form>
                
                
                {preview &&
                <div className="importTicket">
                    <p>Nombre de ticket a importé : <strong>{tabImportTicket.data.length}</strong></p>
                    <p>Temps passé : <strong>{tabImportTicket.costTotal}</strong></p>
                    <p>Cout en ticket : <strong>{tabImportTicket.costTotal*4}</strong></p>

                    <p>Postes importé : </p>
                    <ul>
                        {tabImportTicket.postes.map((poste)=>(
                            <li>{poste}</li>
                        ))}
                    </ul>
                    
                    <p>Tickets a importé: </p>
                    <table>
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Sujet</td>
                                <td>Demande</td>
                                <td>Temps passé</td>
                            </tr>
                        </thead>
                        <tbody>
                            

                        {tabImportTicket.data.map((ticket)=>(
                            <tr>
                                <td>{ticket.date}</td>
                                <td>{ticket.sujet}</td>
                                <td>{ticket.demande}</td>
                                <td>{ticket.costTicket}</td>
                            </tr>
                        ))}
                    

                    </tbody>
                </table>

                    <br />

                    <i>Pour information, le contenu est loggé dans la console javascript </i>
                    
                </div>
                
                }
                
            </div>
            </div>
        </div>
    )
    
    
} 

export default ModalImportTicket;