import Task from "./Task";

const AllTasks = (props) => {


    return (
        <div className="tasks">
        {props.tasks && props.tasks.map(task => (
            <Task task={task} key={task} modeCout={props.modeCout}/>
        ))}
        </div>
    )


}
export default AllTasks;
