import axios from "axios";
import React, {useDebugValue, useEffect, useState} from "react"
import Moment from 'moment';
import { parse } from "papaparse";
import Select from "react-select";


const ModalTempsRessources = ({showModal,setShowModal,ticketsFind,projet,modeAffichage,setModeAffichage,changePeriode,optionsAffichage,periodeString}) =>
{
    const showHideClasseName = showModal ? "modal open" : "modal close";

    const [ressourceTrie,setressourceTrie] = useState([])

    useEffect(()=>{
        let TasksSort = []
        ticketsFind.forEach(ticket => {
            ticket.tasks.forEach(task => {
                if(task.madeByTask){
                    const isAlreadyAdd = TasksSort.find((taskF) => taskF.name.toLowerCase() === task.madeByTask.toLowerCase() && taskF.poste === task.Category)
                    if(!isAlreadyAdd){
                        TasksSort.push({name:task.madeByTask,cost:parseFloat(task.costTask),poste:task.Category})
                    }else{
                        const index = TasksSort.findIndex((taskF) => taskF.name.toLowerCase() === task.madeByTask.toLowerCase() && taskF.poste === task.Category)
                        TasksSort[index].cost += parseFloat(task.costTask)
                    }
                }
            })
        });
        setressourceTrie(TasksSort)
    },[ticketsFind])

    
    return(
        <div className={showHideClasseName}>
            <div className="modal-container temps-passe">
            
            <div className="modal-header">
                <h1>Temps passés</h1>
                <button type="button" className="close" onClick={()=>{setShowModal(false)}}>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                    </svg>
                </button>

            </div>
                <div className="modal-content">
                    <div className="container-periode">
                    <h2>Choisir la période</h2>

                    <div className="select-periode">

                    {modeAffichage !== 'tout' &&
                        <>
                        
                            <div>
                                <svg aria-hidden="true" onClick={()=>{changePeriode('prev')}} focusable="false" data-prefix="far" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>
                                <svg aria-hidden="true" onClick={()=>{changePeriode('next')}} focusable="false" data-prefix="far" data-icon="chevron-right" class="svg-inline--fa fa-chevron-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg>
                            </div>
                       
                        <p>{periodeString}</p>
                        </>
                    }   
                    <Select className="selection-periode" 
                    blurInputOnSelect={false}
                    options={optionsAffichage} 
                    defaultValue={optionsAffichage.find(element => element.value === modeAffichage)} 
                    onChange={(event)=>{setModeAffichage(event.value)}} 
                    isSearchable={false} />
                    </div>
                    </div>

                    <div class="ressources">
                        <div class="ressource head">
                            <div class="collaborateur">Collaborateur</div>
                            <div class="poste">Poste</div>
                            <div class="cout">Temps</div>

                        </div>
                    {ressourceTrie && ressourceTrie.map((ressource)=>(
                    <div key={ressource} className="ressource">
                        <div class="collaborateur">{ressource.name}</div>
                        <div class="poste">{ressource.poste}</div>
                        <div class="cout">{ressource.cost/4} h</div>
                    </div>

                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
    
    
} 

export default ModalTempsRessources;