import React, {useState, useEffect} from "react"
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useHistory } from "react-router";
import GoogleLogin from 'react-google-login';

const Connexion = () => {



    const history = useHistory();
    const [profileGoogle, setprofileGoogle] = useState([])

    useEffect(()=>{

        if(profileGoogle.email){

            axios({
                method: "get",
                url: process.env.REACT_APP_API_URL+"token/google/"+profileGoogle.email,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
                if(res.data.is_connect){
                    localStorage.setItem('token',res.data.new_token)
                    localStorage.setItem('token_createdAt',Date.now())
                    localStorage.setItem('is_connect',true)
                    localStorage.setItem('profileGoogle',JSON.stringify(profileGoogle))
                    history.push("/projets/")
                }else{
                    alert(res.data.error);
                }
            })


        }
        if(localStorage.getItem('is_connect') && localStorage.getItem('token') && localStorage.getItem('profileGoogle')){
            history.push("/projets/")
        }


    },[profileGoogle])





    const responseGoogle = (response) => {
        if(response.profileObj){
            console.log(response.profileObj)
            setprofileGoogle(response.profileObj)
            
        }else{
            alert('La connexion a échoué')
            console.log(response)
        }
    }


    return (
        <div className="connexion white">
            <div className="container">
                <img src="../../img/logo_ticket.png" alt="logo_ticket" />

                
                <GoogleLogin
                    clientId="1079688555472-5j9mcl66f8oju97d2pd99ceq1nm6aqkp.apps.googleusercontent.com"
                    buttonText="Connexion avec votre compte Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    hostedDomain={"r2.fr"}
                />

            </div>
        </div>

    )


}
export default Connexion;