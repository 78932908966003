import Moment from 'moment';
import React, {useState} from "react"
import NumberFormat from 'react-number-format';




const Ticket = ({ticket,onClick}) => {
  

    return (
        <>
                
               

                    <div className="date">
                        <p className="createdAt">{Moment(ticket.CreatedAt).format('L')}</p>
                    </div>
                    <div className="name-ticket">
                        <p className="nom-ticket">{ticket.NameTicket}</p>
                    </div>
                    <div className="description">
                        <p>{ticket.Description}</p>
                    </div>

                    <div className="data">
                        <p className="cout  cout-demandeur">{ticket.Demandeur}</p>
                    </div>

                    <div className="data">
                        <p className="cout cout-ticket"><NumberFormat value={ticket.coutTicket} displayType="text" thousandSeparator={true} /></p>
                    </div>
                    <div className="data">
                        <p className="cout cout-temps"><NumberFormat value={ticket.coutTicket/4} displayType="text" decimalSeparator=","/>h</p>
                    </div>
                    <div className="data">
                        <p className="cout cout-euro"><NumberFormat value={Math.round((ticket.coutTicket*ticket.PriceTicket) * 100) / 100} displayType="text" thousandSeparator={' '} decimalSeparator="," suffix="€"/></p>
                    </div>
               
                
        
        </>
    )


}
export default Ticket;               