import axios from "axios";
import React, {useState} from "react"
import { useHistory } from "react-router";

const ModalAddProjet = ({showModal,setShowModal}) =>
{
    const showHideClasseName = showModal ? "modal open" : "modal close";
    const [nomProjet,setNomProjet] = useState()
    const [logo,setLogo] = useState()
    const [coutTicket,setcoutTicket] = useState()
    const [modeProjet,setModeProjet] = useState('classique')

    const history = useHistory();

 
    const handleSumbit = (e) => {
        e.preventDefault()
        console.log('submit')

        console.log(logo)
        let formData = new FormData()
        formData.append('nom_projet',nomProjet)
        formData.append('logo',logo,logo.name)
        formData.append('cout',coutTicket)
        formData.append('modeProjet',modeProjet)

        axios({
            method: "post",
            url: process.env.REACT_APP_API_URL+"projets",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {

            let data = res.data
            if(data.state === 'success'){
                history.push("/projets/"+data.idProjet)
            }
          })
  
    }
    return(
        <div className={showHideClasseName}>
            <div className="modal-container">
            
            <div className="modal-header">
                <h1>Ajouter un projet</h1>

                <button type="button" className="close" onClick={()=>{setShowModal(false)}}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z" fill="black"/>
                </svg>
                </button>

            </div>
            <div className="modal-content">
                

                <form onSubmit={(e) => handleSumbit(e)}>
                    <div>
                        <input onChange={(e) => setNomProjet(e.target.value)} type="text" placeholder="Nom du projet" />
                    </div>
                    <div>
                        <input onChange={(e) => setcoutTicket(e.target.value)} type="number" placeholder="Coût du ticket par défaut en €" />
                    </div>

                    <div>
                        <p>Mode de maintenance</p>
                        <select value={modeProjet} onChange={(e)=>{setModeProjet(e.target.value)}}>
                            <option value="classique">Suivi au réel</option>
                            <option value="enveloppe">Enveloppe</option>
                            <option value="budget-plancher">Budget plancher</option>
                        </select>
                    </div>
                    <div>
                    <label htmlFor="logo"><strong>Logo du projet</strong></label><br/>
                        <input className="choix-img" onChange={(e) => setLogo(e.target.files[0])} accept="image/png, image/jpeg, .svg" type="file" name="logo"/>
                    </div>
                    <div className="submit-button">
                        <button type="submit">Ajouter le projet</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    )
    
    
} 

export default ModalAddProjet;