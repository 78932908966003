import Header from "../components/Header";
import React, {useState, useEffect} from "react"
import AllBudgets from "../components/AllBudgets";


const Enveloppes = () => {

    return (
        <div className="all-budgets">
            <Header page="budgets"/>
            <AllBudgets />
        </div>
    )
}
export default Enveloppes;